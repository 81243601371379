<template>
  <div id="join-container">
    <div id="room-background">
      <div id="logo-pos">
        <h1 class="playspace-logo">{{$t('global.app_name')}}</h1>
      </div> 
      <div id="join-content">
        <slot />
        <div class="aliens">
            <img src="/img/elements/join/join_friends.svg" />
        </div> 
      </div>
    </div>
  </div>
</template>

<script>
// this is used for logged out pages (login, register, welcome, etc...)
export default {
  name: "joinlayout",
};
</script>

<style lang="scss" >
@import '@/assets/scss/variables';
#join-container {
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 768px;
  z-index: 150;  
  #join-content {
    margin: 0 auto;
    text-align: center;
    width: 100%;
    .heading {
      margin: 80px auto 30px;
      max-width: 400px; //this might be too wide, 300 or 320 might be best
      h2 {
        color: $ps-white;
      }
    }
    .button-group {
      .btn-primary {
        margin:0 15px 30px 0;
      }
    }
    .aliens {
      img {
        margin: 0 -60px 0 0 ;
      }
    }
  }
}
</style>